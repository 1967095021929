import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Loader,
} from "ui-kit-ck-consultant";
import {
  faDownload,
  faSyncAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axiosConfig from "../../utils/axiosConfig";

import { downloadFile } from "../../utils/general";

export default class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getFolders();
  }

  handleDownloadClick = (reference) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("download/download", {
        data: { reference },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({ isLoad: false }, () => {
            downloadFile(res.data.file, res.data.name);
          });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  handleSyncClick = (reference) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("download/sync", {
        data: { reference },
      })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({ isLoad: false }, () => this.getFolders());
          } else {
            this.setState({ isLoad: false }, () => {
              if (!res.data.check) {
                alert("Le dossier n'est pas disponible");
              } else {
                alert("Une erreur est survenue");
              }
            });
          }
        })
        .catch(() =>
          this.setState({ isLoad: false }, () =>
            alert("Une erreur est survenue")
          )
        );
    });
  };

  getFolders() {
    axiosConfig("download/get-folders").then((res) => {
      if (res.data.success === true) {
        this.setState({ folders: res.data.folders });
      } else {
        this.setState({ folders: [] });
      }
    });
  }

  render() {
    return (
      <>
        <Card
          className="mt-10 mt-20-xl"
          classNameChildren="overflow-x-auto"
          title="Télécharger les photos"
        >
          <Table className="mb-20">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>N° de dossier</Th>
                <Th>Date</Th>
                <Th>Nb. Photos</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.folders.map((element, idx) => (
                <Tr key={idx}>
                  <Td
                    className={
                      !element.status ? "bg-red-light" : "bg-green-light"
                    }
                  >
                    {idx + 1}
                  </Td>
                  <Td
                    className={
                      !element.status ? "bg-red-light" : "bg-green-light"
                    }
                  >
                    {element.reference}
                  </Td>
                  <Td
                    className={
                      !element.status ? "bg-red-light" : "bg-green-light"
                    }
                  >
                    {element.date}
                  </Td>
                  <Td
                    className={
                      !element.status ? "bg-red-light" : "bg-green-light"
                    }
                  >
                    {element.count}
                  </Td>
                  <Td
                    className={
                      !element.status ? "bg-red-light" : "bg-green-light"
                    }
                  >
                    {element.statusStr}
                  </Td>
                  <Td
                    className={
                      !element.status ? "bg-red-light" : "bg-green-light"
                    }
                  >
                    {element.archived === 0 ? (
                      <>
                        <ButtonIcon
                          className="green"
                          small
                          onClick={() =>
                            this.handleDownloadClick(element.reference)
                          }
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </ButtonIcon>
                        {!element.status ? (
                          <ButtonIcon
                            className="blue"
                            small
                            onClick={() =>
                              this.handleSyncClick(element.reference)
                            }
                          >
                            <FontAwesomeIcon icon={faSyncAlt} />
                          </ButtonIcon>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <ButtonIcon
                        className="blue"
                        small
                        onClick={() =>
                          alert(
                            "Le dossier est archivé, veuillez contacter l'administrateur"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </ButtonIcon>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
