import React from "react";
import { Redirect } from "react-router-dom";
import { Error404 } from "ui-kit-ck-consultant";

// Layout Types
import DashboardLayout from "./layouts/Dashboard";

// Route Views
import Home from "./views/dashboard/Home";
import Download from "./views/dashboard/Download";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/download",
    exact: true,
    layout: DashboardLayout,
    component: Download,
  },
  {
    layout: Error404,
    component: null,
  },
];

export default routes;
