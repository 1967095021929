import React from "react";
import { Card } from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";

import downloadPlayStore from "../../images/download-play-store.png";
import downloadAppStore from "../../images/download-app-store.png";

export default class Home extends React.Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Card className="mt-10 mt-20-xl" title="Version Android">
            <a href="https://play.google.com/store/apps/details?id=fr.sahadevia.pixtransfer">
              <img
                style={{ maxWidth: "100%", height: "128px" }}
                alt="Download play store"
                src={downloadPlayStore}
              />
            </a>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="mt-10 mt-20-xl" title="Version IOS">
            <a href="https://apps.apple.com/us/app/pixtransfer/id1591042614">
              <img
                style={{ maxWidth: "100%", height: "128px" }}
                alt="Download app store"
                src={downloadAppStore}
              />
            </a>
          </Card>
        </Col>
      </Row>
    );
  }
}
