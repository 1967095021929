import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faFolder } from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon } from "ui-kit-ck-consultant";

import pixtransferLogo from "../images/logo-w.png"

import axiosConfig from "../utils/axiosConfig";

export default class DashboardLayout extends React.Component {
  handleSignOutClick() {
    var url = window.location.href
    var arr = url.split("/");
    axiosConfig("/auth/signout", {
      data: {
        url: arr[0] + "//" + arr[2]
      }
    }).then((res) => {
      if (res.data.success === true) {
        document.location.href = res.data.redirect
      }
    });
  }

  render() {
    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    }
    else {
      return (
        <div className="Dashboard-container">
          <div className="Dashboard-container-body">
            <Header
              logo={pixtransferLogo}
              onClick={() => this.props.history.push('/home')}
              height="32"
            >
              <ButtonIcon style={{ color: "#ffffff" }} onClick={() => this.props.history.push('/download')}>
                <FontAwesomeIcon icon={faFolder} />
              </ButtonIcon>
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.handleSignOutClick()}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div className="Dashboard-content">
              {this.props.connected === true ? this.props.children : ""}
            </div>
          </div>
        </div>
      )
    }
  }
}