import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";

import axiosConfig from "./utils/axiosConfig";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  checkAuth = () => {
    const pathArray = document.location.href.split("/");
    if (navigator.onLine) {
      var url = window.location.href;
      var arr = url.split("/");
      let token = null;
      if (pathArray.includes("success")) {
        token = pathArray[pathArray.length - 1];
      }
      axiosConfig("/auth/refresh-token", {
        data: {
          token: token,
          url: arr[0] + "//" + arr[2],
        },
      }).then((res) => {
        if (res.data.success === true) {
          if (res.data.redirect) {
            document.location.href = res.data.redirect;
          } else {
            if (pathArray.includes("success")) {
              document.location.href = "/";
            } else {
              const oldState = this.state;
              const newState = {
                isReady: true,
                connected: true,
              };
              if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
                this.setState(newState);
              }
            }
          }
        } else {
          this.setState({ connected: false }, () => {
            setTimeout(() => {
              document.location.href = res.data.redirect;
            }, 1000);
          });
        }
      });
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  render() {
    if (!this.state.isReady) {
      return <></>;
    }

    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return (
                    <route.layout {...props} connected={this.state.connected}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
        </Switch>
      </Router>
    );
  }
}
